import cx from 'classnames';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {convertToQueryParams, parseParams} from '@parca/utilities';
import {useState} from 'react';
import {usePopper} from 'react-popper';

const ProjectSubMenu = ({
  projectId,
  enableLogs,
  enableTrace,
  enableSQLExplorer,
}: {
  projectId: string;
  enableLogs: boolean;
  enableTrace: boolean;
  enableSQLExplorer: boolean;
}) => {
  const router = useRouter();

  const queryParams = new URLSearchParams(location.search);
  const expressionA = queryParams.get('expression_a');
  const expressionB = queryParams.get('expression_b');
  const comparing = queryParams.get('comparing');

  const queryParamsURL = parseParams(window.location.search);

  const isComparePage = expressionA !== null && expressionB !== null;

  const [compareHover, setCompareHover] = useState<boolean>(false);
  const [comparePopperReferenceElement, setComparePopperReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [comparePopperElement, setComparePopperElement] = useState<HTMLDivElement | null>(null);
  const {styles, attributes} = usePopper(comparePopperReferenceElement, comparePopperElement, {
    placement: 'bottom',
  });

  const compareExplanation =
    'Compare two profiles and see the relative difference between them more clearly.';

  const navigateTo = (path: string, queryParams: any) => {
    router.push({
      pathname: `/projects/${projectId}`,
      query: convertToQueryParams(queryParams),
    });
  };

  const isProjectLogsPage = router.pathname.includes('logs');
  const isProjectTracePage = router.pathname.includes('trace');
  const isProjectSQLExplorerPage = router.pathname.includes('sql-explorer');
  const isProjectSettingsPage = router.pathname.includes('settings');
  const isDebugInfoPage = router.pathname.includes('debug-info');

  const isProjectProfilerPage =
    !isProjectSettingsPage &&
    !isProjectLogsPage &&
    !isProjectTracePage &&
    !isDebugInfoPage &&
    !isComparePage &&
    !isProjectSQLExplorerPage;
  comparing !== 'true';

  const queryToBePassed = {...queryParamsURL, comparing: 'true'};

  return (
    <div className="flex gap-4 px-4 text-sm">
      <Link
        href="/"
        className={cx(
          'rounded-sm px-3 py-2.5 hover:bg-gray-100 dark:hover:bg-gray-800',
          isProjectProfilerPage && 'border-b-2 border-blue-600'
        )}
      >
        Profiler
      </Link>
      <div ref={setComparePopperReferenceElement}>
        <button
          onClick={() =>
            navigateTo('/', {
              ...queryToBePassed,
            })
          }
          onMouseEnter={() => setCompareHover(true)}
          onMouseLeave={() => setCompareHover(false)}
          id="h-compare-button"
          className={cx(
            'rounded-sm px-3 py-2.5 hover:bg-gray-100 dark:hover:bg-gray-800',
            (isComparePage || comparing === 'true') && 'border-b-2 border-blue-600'
          )}
        >
          Compare
        </button>
        {compareHover && (
          <div
            ref={setComparePopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="z-50"
          >
            <div className="flex">
              <div className="relative mx-2">
                <svg
                  className="left-0 h-1 w-full text-black"
                  x="0px"
                  y="0px"
                  viewBox="0 0 255 127.5"
                >
                  <polygon className="fill-current" points="0,127.5 127.5,0 255,127.5" />
                </svg>
                <div className="right-0 w-40 rounded bg-black px-3 py-2 text-xs text-white">
                  {compareExplanation}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {enableLogs && (
        <Link
          href={`/projects/${projectId}/logs`}
          className={cx(
            'rounded-sm px-3 py-2.5 hover:bg-gray-100 dark:hover:bg-gray-800',
            isProjectLogsPage && 'border-b-2 border-blue-600'
          )}
        >
          Logs
        </Link>
      )}
      {enableTrace && (
        <Link
          href={`/projects/${projectId}/trace`}
          className={cx(
            'rounded-sm px-3 py-2.5 hover:bg-gray-100 dark:hover:bg-gray-800',
            isProjectTracePage && 'border-b-2 border-blue-600'
          )}
        >
          Trace
        </Link>
      )}
      {enableSQLExplorer ? (
        <Link
          href={`/projects/${projectId}/sql-explorer`}
          className={cx('rounded-sm px-3 py-2.5 hover:bg-gray-100 dark:hover:bg-gray-800', {
            'border-b-2 border-blue-600': isProjectSQLExplorerPage,
          })}
        >
          SQL Explorer
        </Link>
      ) : null}
      <Link
        href={`/projects/${projectId}/debug-info`}
        className={cx(
          'rounded-sm px-3 py-2.5 hover:bg-gray-100 dark:hover:bg-gray-800',
          isDebugInfoPage && 'border-b-2 border-blue-600'
        )}
      >
        Debug Info
      </Link>
      <Link
        href={`/projects/${projectId}/settings`}
        className={cx(
          'rounded-sm px-3 py-2.5 hover:bg-gray-100 dark:hover:bg-gray-800',
          isProjectSettingsPage && 'border-b-2 border-blue-600'
        )}
      >
        Settings
      </Link>
    </div>
  );
};

export default ProjectSubMenu;
