import {useEffect, useState} from 'react';

import {Icon} from '@iconify/react';
import {Spinner} from '@polarsignals/components';
import {useRouter} from 'next/router';
import {Flex, Grid, Paragraph} from 'theme-ui';

import Input from 'components/Input';
import Section from 'components/Section';
import {useOrganizationSSOSettings} from 'hooks/data/useOrganizationSSOSettings';

import {LoginButton, login} from './LoginButton';

interface Props {
  ssoIdentifier?: string;
  onAllLoginOptions?: () => void;
}

export const SSOLogin = ({ssoIdentifier, onAllLoginOptions}: Props) => {
  const router = useRouter();
  const [orgId, setOrgId] = useState((router.query.org as string) ?? ssoIdentifier ?? '');
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [autoLoginInitiated, setAutoLoginInitiated] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const {mutations} = useOrganizationSSOSettings({});

  useEffect(() => {
    if (router.query.autologin === 'true' && !autoLoginInitiated) {
      initateSSOLogin();
      setAutoLoginInitiated(true);
    }
  }, [router.query.autologin, autoLoginInitiated]);

  const resetForm = () => {
    setErrorMsg(undefined);
  };

  const validateOrgId = async () => {
    try {
      const connectorId = await mutations.getConnectorId(orgId);

      return connectorId;
    } catch (e) {
      console.log('Error getting connectorId', e);
      return undefined;
    }
  };

  const initateSSOLogin = async () => {
    setLoginInProgress(true);
    let connectorId;
    try {
      connectorId = await validateOrgId();
    } catch (e) {
      console.log('Error getting connectorId', e);
    }

    if (connectorId === undefined) {
      setLoginInProgress(false);
      return void setErrorMsg(
        'Invalid organization identifier. Please contact your organization admin for assistance.'
      );
    }

    try {
      login(connectorId, router.query);
    } catch (e) {
      console.log('Error initiating SSO login', e);
      setLoginInProgress(false);
      setErrorMsg('Something went wrong. Please try again.');
    }
  };

  return (
    <Flex className="items-center justify-center ">
      <Section variant="layout.container.default">
        <Flex sx={{flexDirection: 'column'}} className="w-fit max-w-[420px] items-center gap-4 p-8">
          <Paragraph className="text-center">
            Please enter your organization identifier to proceed authenticating with your SSO
            provider.
          </Paragraph>
          <Grid className="w-80 grid-rows-2 pt-4">
            <Input
              value={orgId}
              onChange={e => {
                // @ts-expect-error Input's event seems to be typed incorrectly
                setOrgId(e.target.value as string);
                resetForm();
              }}
              className="mb-4 w-full"
            />
            {errorMsg != null ? <p className="text-sm text-red-500">{errorMsg}</p> : null}
            <LoginButton
              onClick={() => void initateSSOLogin()}
              icon={<Icon icon="carbon:network-enterprise" height={22} />}
              text={
                <span className="flex items-center whitespace-nowrap">
                  Sign in with SSO {loginInProgress ? <Spinner size={24} /> : null}
                </span>
              }
              textColor="text"
              background="#fff"
              title="Sign in with SSO"
              disabled={orgId === '' || loginInProgress}
            />
            <a className="text-sm cursor-pointer underline text-center" onClick={onAllLoginOptions}>
              Try other login methods
            </a>
          </Grid>
          <Paragraph className="text-center text-sm">
            If you're having trouble logging in, please contact your organization admin.
          </Paragraph>
        </Flex>
      </Section>
    </Flex>
  );
};
