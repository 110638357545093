// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "polarsignals/project/v1alpha1/project.proto" (package "polarsignals.project.v1alpha1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ProjectService } from "./project";
import type { GetLoginConfigForProjectResponse } from "./project";
import type { GetLoginConfigForProjectRequest } from "./project";
import type { GetProjectDebuginfosCountResponse } from "./debuginfo";
import type { GetProjectDebuginfosCountRequest } from "./debuginfo";
import type { GetProjectDebuginfosResponse } from "./debuginfo";
import type { GetProjectDebuginfosRequest } from "./debuginfo";
import type { DeleteProjectTokenResponse } from "./project";
import type { DeleteProjectTokenRequest } from "./project";
import type { CreateProjectTokenResponse } from "./project";
import type { CreateProjectTokenRequest } from "./project";
import type { GetProjectTokensResponse } from "./project";
import type { GetProjectTokensRequest } from "./project";
import type { DeleteProjectResponse } from "./project";
import type { DeleteProjectRequest } from "./project";
import type { UpdateProjectResponse } from "./project";
import type { UpdateProjectRequest } from "./project";
import type { GetProjectResponse } from "./project";
import type { GetProjectRequest } from "./project";
import type { CreateProjectResponse } from "./project";
import type { CreateProjectRequest } from "./project";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetProjectsResponse } from "./project";
import type { GetProjectsRequest } from "./project";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * ProjectService provides the ability to manage projects.
 *
 * @generated from protobuf service polarsignals.project.v1alpha1.ProjectService
 */
export interface IProjectServiceClient {
    /**
     * GetProjects returns the project with the given ID.
     *
     * @generated from protobuf rpc: GetProjects(polarsignals.project.v1alpha1.GetProjectsRequest) returns (polarsignals.project.v1alpha1.GetProjectsResponse);
     */
    getProjects(input: GetProjectsRequest, options?: RpcOptions): UnaryCall<GetProjectsRequest, GetProjectsResponse>;
    /**
     * CreateProject creates a new project.
     *
     * @generated from protobuf rpc: CreateProject(polarsignals.project.v1alpha1.CreateProjectRequest) returns (polarsignals.project.v1alpha1.CreateProjectResponse);
     */
    createProject(input: CreateProjectRequest, options?: RpcOptions): UnaryCall<CreateProjectRequest, CreateProjectResponse>;
    /**
     * GetProject returns the project with the given ID.
     *
     * @generated from protobuf rpc: GetProject(polarsignals.project.v1alpha1.GetProjectRequest) returns (polarsignals.project.v1alpha1.GetProjectResponse);
     */
    getProject(input: GetProjectRequest, options?: RpcOptions): UnaryCall<GetProjectRequest, GetProjectResponse>;
    /**
     * UpdateProject updates the project with the given ID.
     *
     * @generated from protobuf rpc: UpdateProject(polarsignals.project.v1alpha1.UpdateProjectRequest) returns (polarsignals.project.v1alpha1.UpdateProjectResponse);
     */
    updateProject(input: UpdateProjectRequest, options?: RpcOptions): UnaryCall<UpdateProjectRequest, UpdateProjectResponse>;
    /**
     * DeleteProject deletes the project with the given ID.
     *
     * @generated from protobuf rpc: DeleteProject(polarsignals.project.v1alpha1.DeleteProjectRequest) returns (polarsignals.project.v1alpha1.DeleteProjectResponse);
     */
    deleteProject(input: DeleteProjectRequest, options?: RpcOptions): UnaryCall<DeleteProjectRequest, DeleteProjectResponse>;
    /**
     * GetProjectTokens returns the tokens for the project with the given ID.
     *
     * @generated from protobuf rpc: GetProjectTokens(polarsignals.project.v1alpha1.GetProjectTokensRequest) returns (polarsignals.project.v1alpha1.GetProjectTokensResponse);
     */
    getProjectTokens(input: GetProjectTokensRequest, options?: RpcOptions): UnaryCall<GetProjectTokensRequest, GetProjectTokensResponse>;
    /**
     * CreateProjectToken creates a new token for the project with the given ID.
     *
     * @generated from protobuf rpc: CreateProjectToken(polarsignals.project.v1alpha1.CreateProjectTokenRequest) returns (polarsignals.project.v1alpha1.CreateProjectTokenResponse);
     */
    createProjectToken(input: CreateProjectTokenRequest, options?: RpcOptions): UnaryCall<CreateProjectTokenRequest, CreateProjectTokenResponse>;
    /**
     * DeleteProjectToken deletes the token with the given ID.
     *
     * @generated from protobuf rpc: DeleteProjectToken(polarsignals.project.v1alpha1.DeleteProjectTokenRequest) returns (polarsignals.project.v1alpha1.DeleteProjectTokenResponse);
     */
    deleteProjectToken(input: DeleteProjectTokenRequest, options?: RpcOptions): UnaryCall<DeleteProjectTokenRequest, DeleteProjectTokenResponse>;
    /**
     * GetProjectDebuginfos returns the debug infos for a given project.
     *
     * @generated from protobuf rpc: GetProjectDebuginfos(polarsignals.project.v1alpha1.GetProjectDebuginfosRequest) returns (polarsignals.project.v1alpha1.GetProjectDebuginfosResponse);
     */
    getProjectDebuginfos(input: GetProjectDebuginfosRequest, options?: RpcOptions): UnaryCall<GetProjectDebuginfosRequest, GetProjectDebuginfosResponse>;
    /**
     * GetProjectDebuginfosCount returns the count of debug infos for a given project.
     *
     * @generated from protobuf rpc: GetProjectDebuginfosCount(polarsignals.project.v1alpha1.GetProjectDebuginfosCountRequest) returns (polarsignals.project.v1alpha1.GetProjectDebuginfosCountResponse);
     */
    getProjectDebuginfosCount(input: GetProjectDebuginfosCountRequest, options?: RpcOptions): UnaryCall<GetProjectDebuginfosCountRequest, GetProjectDebuginfosCountResponse>;
    /**
     * GetLoginConfigForProject returns the login config for a given project.
     *
     * @generated from protobuf rpc: GetLoginConfigForProject(polarsignals.project.v1alpha1.GetLoginConfigForProjectRequest) returns (polarsignals.project.v1alpha1.GetLoginConfigForProjectResponse);
     */
    getLoginConfigForProject(input: GetLoginConfigForProjectRequest, options?: RpcOptions): UnaryCall<GetLoginConfigForProjectRequest, GetLoginConfigForProjectResponse>;
}
/**
 * ProjectService provides the ability to manage projects.
 *
 * @generated from protobuf service polarsignals.project.v1alpha1.ProjectService
 */
export class ProjectServiceClient implements IProjectServiceClient, ServiceInfo {
    typeName = ProjectService.typeName;
    methods = ProjectService.methods;
    options = ProjectService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * GetProjects returns the project with the given ID.
     *
     * @generated from protobuf rpc: GetProjects(polarsignals.project.v1alpha1.GetProjectsRequest) returns (polarsignals.project.v1alpha1.GetProjectsResponse);
     */
    getProjects(input: GetProjectsRequest, options?: RpcOptions): UnaryCall<GetProjectsRequest, GetProjectsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProjectsRequest, GetProjectsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * CreateProject creates a new project.
     *
     * @generated from protobuf rpc: CreateProject(polarsignals.project.v1alpha1.CreateProjectRequest) returns (polarsignals.project.v1alpha1.CreateProjectResponse);
     */
    createProject(input: CreateProjectRequest, options?: RpcOptions): UnaryCall<CreateProjectRequest, CreateProjectResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateProjectRequest, CreateProjectResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetProject returns the project with the given ID.
     *
     * @generated from protobuf rpc: GetProject(polarsignals.project.v1alpha1.GetProjectRequest) returns (polarsignals.project.v1alpha1.GetProjectResponse);
     */
    getProject(input: GetProjectRequest, options?: RpcOptions): UnaryCall<GetProjectRequest, GetProjectResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProjectRequest, GetProjectResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * UpdateProject updates the project with the given ID.
     *
     * @generated from protobuf rpc: UpdateProject(polarsignals.project.v1alpha1.UpdateProjectRequest) returns (polarsignals.project.v1alpha1.UpdateProjectResponse);
     */
    updateProject(input: UpdateProjectRequest, options?: RpcOptions): UnaryCall<UpdateProjectRequest, UpdateProjectResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateProjectRequest, UpdateProjectResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * DeleteProject deletes the project with the given ID.
     *
     * @generated from protobuf rpc: DeleteProject(polarsignals.project.v1alpha1.DeleteProjectRequest) returns (polarsignals.project.v1alpha1.DeleteProjectResponse);
     */
    deleteProject(input: DeleteProjectRequest, options?: RpcOptions): UnaryCall<DeleteProjectRequest, DeleteProjectResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteProjectRequest, DeleteProjectResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetProjectTokens returns the tokens for the project with the given ID.
     *
     * @generated from protobuf rpc: GetProjectTokens(polarsignals.project.v1alpha1.GetProjectTokensRequest) returns (polarsignals.project.v1alpha1.GetProjectTokensResponse);
     */
    getProjectTokens(input: GetProjectTokensRequest, options?: RpcOptions): UnaryCall<GetProjectTokensRequest, GetProjectTokensResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProjectTokensRequest, GetProjectTokensResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * CreateProjectToken creates a new token for the project with the given ID.
     *
     * @generated from protobuf rpc: CreateProjectToken(polarsignals.project.v1alpha1.CreateProjectTokenRequest) returns (polarsignals.project.v1alpha1.CreateProjectTokenResponse);
     */
    createProjectToken(input: CreateProjectTokenRequest, options?: RpcOptions): UnaryCall<CreateProjectTokenRequest, CreateProjectTokenResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateProjectTokenRequest, CreateProjectTokenResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * DeleteProjectToken deletes the token with the given ID.
     *
     * @generated from protobuf rpc: DeleteProjectToken(polarsignals.project.v1alpha1.DeleteProjectTokenRequest) returns (polarsignals.project.v1alpha1.DeleteProjectTokenResponse);
     */
    deleteProjectToken(input: DeleteProjectTokenRequest, options?: RpcOptions): UnaryCall<DeleteProjectTokenRequest, DeleteProjectTokenResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteProjectTokenRequest, DeleteProjectTokenResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetProjectDebuginfos returns the debug infos for a given project.
     *
     * @generated from protobuf rpc: GetProjectDebuginfos(polarsignals.project.v1alpha1.GetProjectDebuginfosRequest) returns (polarsignals.project.v1alpha1.GetProjectDebuginfosResponse);
     */
    getProjectDebuginfos(input: GetProjectDebuginfosRequest, options?: RpcOptions): UnaryCall<GetProjectDebuginfosRequest, GetProjectDebuginfosResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProjectDebuginfosRequest, GetProjectDebuginfosResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetProjectDebuginfosCount returns the count of debug infos for a given project.
     *
     * @generated from protobuf rpc: GetProjectDebuginfosCount(polarsignals.project.v1alpha1.GetProjectDebuginfosCountRequest) returns (polarsignals.project.v1alpha1.GetProjectDebuginfosCountResponse);
     */
    getProjectDebuginfosCount(input: GetProjectDebuginfosCountRequest, options?: RpcOptions): UnaryCall<GetProjectDebuginfosCountRequest, GetProjectDebuginfosCountResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProjectDebuginfosCountRequest, GetProjectDebuginfosCountResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetLoginConfigForProject returns the login config for a given project.
     *
     * @generated from protobuf rpc: GetLoginConfigForProject(polarsignals.project.v1alpha1.GetLoginConfigForProjectRequest) returns (polarsignals.project.v1alpha1.GetLoginConfigForProjectResponse);
     */
    getLoginConfigForProject(input: GetLoginConfigForProjectRequest, options?: RpcOptions): UnaryCall<GetLoginConfigForProjectRequest, GetLoginConfigForProjectResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetLoginConfigForProjectRequest, GetLoginConfigForProjectResponse>("unary", this._transport, method, opt, input);
    }
}
