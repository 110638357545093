// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "polarsignals/view/v1alpha1/view.proto" (package "polarsignals.view.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * view represents a view.
 *
 * @generated from protobuf message polarsignals.view.v1alpha1.View
 */
export interface View {
    /**
     * The ID of the view.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the view.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * The description of the view.
     *
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * The slug for the view.
     *
     * @generated from protobuf field: string slug = 4;
     */
    slug: string;
    /**
     * The query for the view.
     *
     * @generated from protobuf field: string query = 5;
     */
    query: string;
    /**
     * The ID of the project this view belongs to.
     *
     * @generated from protobuf field: string project_id = 6;
     */
    projectId: string;
    /**
     * Whether to include a profile type to be enforced in the view.
     *
     * @generated from protobuf field: bool include_profile_type = 7;
     */
    includeProfileType: boolean;
    /**
     * A list of label names to use for querying the view.
     *
     * @generated from protobuf field: repeated string label_names = 8;
     */
    labelNames: string[];
    /**
     * Whether to disable explorative querying
     *
     * @generated from protobuf field: bool disable_explorative_querying = 9;
     */
    disableExplorativeQuerying: boolean;
}
/**
 * GetViewsRequest is the request for the GetViews method.
 *
 * @generated from protobuf message polarsignals.view.v1alpha1.GetViewsRequest
 */
export interface GetViewsRequest {
    /**
     * The ID of the project.
     *
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
}
/**
 * GetViewsResponse is the response for the GetViews method.
 *
 * @generated from protobuf message polarsignals.view.v1alpha1.GetViewsResponse
 */
export interface GetViewsResponse {
    /**
     * The views.
     *
     * @generated from protobuf field: repeated polarsignals.view.v1alpha1.View views = 1;
     */
    views: View[];
}
/**
 * CreateViewRequest is the request for the CreateView method.
 *
 * @generated from protobuf message polarsignals.view.v1alpha1.CreateViewRequest
 */
export interface CreateViewRequest {
    /**
     * The name of the view.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The ID of the project.
     *
     * @generated from protobuf field: string project_id = 2;
     */
    projectId: string;
    /**
     * The description of the view.
     *
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * The slug for the view
     *
     * @generated from protobuf field: string slug = 4;
     */
    slug: string;
    /**
     * The query for the view.
     *
     * @generated from protobuf field: string query = 5;
     */
    query: string;
    /**
     * Whether to include a profile type to be enforced in the view.
     *
     * @generated from protobuf field: bool include_profile_type = 6;
     */
    includeProfileType: boolean;
    /**
     * A list of label names to use for querying the view.
     *
     * @generated from protobuf field: repeated string label_names = 7;
     */
    labelNames: string[];
    /**
     * Whether to disable explorative querying
     *
     * @generated from protobuf field: bool disable_explorative_querying = 8;
     */
    disableExplorativeQuerying: boolean;
}
/**
 * CreateViewResponse is the response for the CreateView method.
 *
 * @generated from protobuf message polarsignals.view.v1alpha1.CreateViewResponse
 */
export interface CreateViewResponse {
    /**
     * The slug for the view.
     *
     * @generated from protobuf field: string slug = 1;
     */
    slug: string;
}
/**
 * GetViewRequest is the request for the GetView method.
 *
 * @generated from protobuf message polarsignals.view.v1alpha1.GetViewRequest
 */
export interface GetViewRequest {
    /**
     * The slug for the view.
     *
     * @generated from protobuf field: string slug = 1;
     */
    slug: string;
    /**
     * The ID of the project.
     *
     * @generated from protobuf field: string project_id = 2;
     */
    projectId: string;
}
/**
 * GetViewResponse is the response for the GetView method.
 *
 * @generated from protobuf message polarsignals.view.v1alpha1.GetViewResponse
 */
export interface GetViewResponse {
    /**
     * The view.
     *
     * @generated from protobuf field: polarsignals.view.v1alpha1.View view = 1;
     */
    view?: View;
}
/**
 * UpdateViewRequest is the request for the UpdateView method.
 *
 * @generated from protobuf message polarsignals.view.v1alpha1.UpdateViewRequest
 */
export interface UpdateViewRequest {
    /**
     * The ID of the view.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the view.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * The description of the view.
     *
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * The slug for the view
     *
     * @generated from protobuf field: string slug = 4;
     */
    slug: string;
    /**
     * The query for the view.
     *
     * @generated from protobuf field: string query = 5;
     */
    query: string;
    /**
     * The ID of the project.
     *
     * @generated from protobuf field: string project_id = 6;
     */
    projectId: string;
    /**
     * Whether to include a profile type to be enforced in the view.
     *
     * @generated from protobuf field: bool include_profile_type = 7;
     */
    includeProfileType: boolean;
    /**
     * A list of label names to use for querying the view.
     *
     * @generated from protobuf field: repeated string label_names = 8;
     */
    labelNames: string[];
    /**
     * Whether to disable explorative querying
     *
     * @generated from protobuf field: bool disable_explorative_querying = 9;
     */
    disableExplorativeQuerying: boolean;
}
/**
 * UpdateViewResponse is the response for the UpdateView method.
 *
 * @generated from protobuf message polarsignals.view.v1alpha1.UpdateViewResponse
 */
export interface UpdateViewResponse {
    /**
     * The view.
     *
     * @generated from protobuf field: polarsignals.view.v1alpha1.View view = 1;
     */
    view?: View;
}
/**
 * DeleteViewRequest is the request for the DeleteView method.
 *
 * @generated from protobuf message polarsignals.view.v1alpha1.DeleteViewRequest
 */
export interface DeleteViewRequest {
    /**
     * The slug for the view.
     *
     * @generated from protobuf field: string slug = 1;
     */
    slug: string;
    /**
     * The ID of the project.
     *
     * @generated from protobuf field: string project_id = 2;
     */
    projectId: string;
}
/**
 * DeleteViewResponse is the response for the DeleteView method.
 *
 * @generated from protobuf message polarsignals.view.v1alpha1.DeleteViewResponse
 */
export interface DeleteViewResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class View$Type extends MessageType<View> {
    constructor() {
        super("polarsignals.view.v1alpha1.View", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "include_profile_type", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "label_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "disable_explorative_querying", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<View>): View {
        const message = { id: "", name: "", description: "", slug: "", query: "", projectId: "", includeProfileType: false, labelNames: [], disableExplorativeQuerying: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<View>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: View): View {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string slug */ 4:
                    message.slug = reader.string();
                    break;
                case /* string query */ 5:
                    message.query = reader.string();
                    break;
                case /* string project_id */ 6:
                    message.projectId = reader.string();
                    break;
                case /* bool include_profile_type */ 7:
                    message.includeProfileType = reader.bool();
                    break;
                case /* repeated string label_names */ 8:
                    message.labelNames.push(reader.string());
                    break;
                case /* bool disable_explorative_querying */ 9:
                    message.disableExplorativeQuerying = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: View, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string slug = 4; */
        if (message.slug !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.slug);
        /* string query = 5; */
        if (message.query !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.query);
        /* string project_id = 6; */
        if (message.projectId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.projectId);
        /* bool include_profile_type = 7; */
        if (message.includeProfileType !== false)
            writer.tag(7, WireType.Varint).bool(message.includeProfileType);
        /* repeated string label_names = 8; */
        for (let i = 0; i < message.labelNames.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.labelNames[i]);
        /* bool disable_explorative_querying = 9; */
        if (message.disableExplorativeQuerying !== false)
            writer.tag(9, WireType.Varint).bool(message.disableExplorativeQuerying);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.view.v1alpha1.View
 */
export const View = new View$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetViewsRequest$Type extends MessageType<GetViewsRequest> {
    constructor() {
        super("polarsignals.view.v1alpha1.GetViewsRequest", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetViewsRequest>): GetViewsRequest {
        const message = { projectId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetViewsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetViewsRequest): GetViewsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetViewsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.view.v1alpha1.GetViewsRequest
 */
export const GetViewsRequest = new GetViewsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetViewsResponse$Type extends MessageType<GetViewsResponse> {
    constructor() {
        super("polarsignals.view.v1alpha1.GetViewsResponse", [
            { no: 1, name: "views", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => View }
        ]);
    }
    create(value?: PartialMessage<GetViewsResponse>): GetViewsResponse {
        const message = { views: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetViewsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetViewsResponse): GetViewsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated polarsignals.view.v1alpha1.View views */ 1:
                    message.views.push(View.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetViewsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated polarsignals.view.v1alpha1.View views = 1; */
        for (let i = 0; i < message.views.length; i++)
            View.internalBinaryWrite(message.views[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.view.v1alpha1.GetViewsResponse
 */
export const GetViewsResponse = new GetViewsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateViewRequest$Type extends MessageType<CreateViewRequest> {
    constructor() {
        super("polarsignals.view.v1alpha1.CreateViewRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "include_profile_type", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "label_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "disable_explorative_querying", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CreateViewRequest>): CreateViewRequest {
        const message = { name: "", projectId: "", description: "", slug: "", query: "", includeProfileType: false, labelNames: [], disableExplorativeQuerying: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateViewRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateViewRequest): CreateViewRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string slug */ 4:
                    message.slug = reader.string();
                    break;
                case /* string query */ 5:
                    message.query = reader.string();
                    break;
                case /* bool include_profile_type */ 6:
                    message.includeProfileType = reader.bool();
                    break;
                case /* repeated string label_names */ 7:
                    message.labelNames.push(reader.string());
                    break;
                case /* bool disable_explorative_querying */ 8:
                    message.disableExplorativeQuerying = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateViewRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string project_id = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string slug = 4; */
        if (message.slug !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.slug);
        /* string query = 5; */
        if (message.query !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.query);
        /* bool include_profile_type = 6; */
        if (message.includeProfileType !== false)
            writer.tag(6, WireType.Varint).bool(message.includeProfileType);
        /* repeated string label_names = 7; */
        for (let i = 0; i < message.labelNames.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.labelNames[i]);
        /* bool disable_explorative_querying = 8; */
        if (message.disableExplorativeQuerying !== false)
            writer.tag(8, WireType.Varint).bool(message.disableExplorativeQuerying);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.view.v1alpha1.CreateViewRequest
 */
export const CreateViewRequest = new CreateViewRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateViewResponse$Type extends MessageType<CreateViewResponse> {
    constructor() {
        super("polarsignals.view.v1alpha1.CreateViewResponse", [
            { no: 1, name: "slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateViewResponse>): CreateViewResponse {
        const message = { slug: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateViewResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateViewResponse): CreateViewResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string slug */ 1:
                    message.slug = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateViewResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string slug = 1; */
        if (message.slug !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.slug);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.view.v1alpha1.CreateViewResponse
 */
export const CreateViewResponse = new CreateViewResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetViewRequest$Type extends MessageType<GetViewRequest> {
    constructor() {
        super("polarsignals.view.v1alpha1.GetViewRequest", [
            { no: 1, name: "slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetViewRequest>): GetViewRequest {
        const message = { slug: "", projectId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetViewRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetViewRequest): GetViewRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string slug */ 1:
                    message.slug = reader.string();
                    break;
                case /* string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetViewRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string slug = 1; */
        if (message.slug !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.slug);
        /* string project_id = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.view.v1alpha1.GetViewRequest
 */
export const GetViewRequest = new GetViewRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetViewResponse$Type extends MessageType<GetViewResponse> {
    constructor() {
        super("polarsignals.view.v1alpha1.GetViewResponse", [
            { no: 1, name: "view", kind: "message", T: () => View }
        ]);
    }
    create(value?: PartialMessage<GetViewResponse>): GetViewResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetViewResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetViewResponse): GetViewResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* polarsignals.view.v1alpha1.View view */ 1:
                    message.view = View.internalBinaryRead(reader, reader.uint32(), options, message.view);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetViewResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* polarsignals.view.v1alpha1.View view = 1; */
        if (message.view)
            View.internalBinaryWrite(message.view, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.view.v1alpha1.GetViewResponse
 */
export const GetViewResponse = new GetViewResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateViewRequest$Type extends MessageType<UpdateViewRequest> {
    constructor() {
        super("polarsignals.view.v1alpha1.UpdateViewRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "include_profile_type", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "label_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "disable_explorative_querying", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateViewRequest>): UpdateViewRequest {
        const message = { id: "", name: "", description: "", slug: "", query: "", projectId: "", includeProfileType: false, labelNames: [], disableExplorativeQuerying: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateViewRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateViewRequest): UpdateViewRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string slug */ 4:
                    message.slug = reader.string();
                    break;
                case /* string query */ 5:
                    message.query = reader.string();
                    break;
                case /* string project_id */ 6:
                    message.projectId = reader.string();
                    break;
                case /* bool include_profile_type */ 7:
                    message.includeProfileType = reader.bool();
                    break;
                case /* repeated string label_names */ 8:
                    message.labelNames.push(reader.string());
                    break;
                case /* bool disable_explorative_querying */ 9:
                    message.disableExplorativeQuerying = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateViewRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string slug = 4; */
        if (message.slug !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.slug);
        /* string query = 5; */
        if (message.query !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.query);
        /* string project_id = 6; */
        if (message.projectId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.projectId);
        /* bool include_profile_type = 7; */
        if (message.includeProfileType !== false)
            writer.tag(7, WireType.Varint).bool(message.includeProfileType);
        /* repeated string label_names = 8; */
        for (let i = 0; i < message.labelNames.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.labelNames[i]);
        /* bool disable_explorative_querying = 9; */
        if (message.disableExplorativeQuerying !== false)
            writer.tag(9, WireType.Varint).bool(message.disableExplorativeQuerying);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.view.v1alpha1.UpdateViewRequest
 */
export const UpdateViewRequest = new UpdateViewRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateViewResponse$Type extends MessageType<UpdateViewResponse> {
    constructor() {
        super("polarsignals.view.v1alpha1.UpdateViewResponse", [
            { no: 1, name: "view", kind: "message", T: () => View }
        ]);
    }
    create(value?: PartialMessage<UpdateViewResponse>): UpdateViewResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateViewResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateViewResponse): UpdateViewResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* polarsignals.view.v1alpha1.View view */ 1:
                    message.view = View.internalBinaryRead(reader, reader.uint32(), options, message.view);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateViewResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* polarsignals.view.v1alpha1.View view = 1; */
        if (message.view)
            View.internalBinaryWrite(message.view, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.view.v1alpha1.UpdateViewResponse
 */
export const UpdateViewResponse = new UpdateViewResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteViewRequest$Type extends MessageType<DeleteViewRequest> {
    constructor() {
        super("polarsignals.view.v1alpha1.DeleteViewRequest", [
            { no: 1, name: "slug", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteViewRequest>): DeleteViewRequest {
        const message = { slug: "", projectId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteViewRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteViewRequest): DeleteViewRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string slug */ 1:
                    message.slug = reader.string();
                    break;
                case /* string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteViewRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string slug = 1; */
        if (message.slug !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.slug);
        /* string project_id = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.view.v1alpha1.DeleteViewRequest
 */
export const DeleteViewRequest = new DeleteViewRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteViewResponse$Type extends MessageType<DeleteViewResponse> {
    constructor() {
        super("polarsignals.view.v1alpha1.DeleteViewResponse", []);
    }
    create(value?: PartialMessage<DeleteViewResponse>): DeleteViewResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteViewResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteViewResponse): DeleteViewResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteViewResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.view.v1alpha1.DeleteViewResponse
 */
export const DeleteViewResponse = new DeleteViewResponse$Type();
/**
 * @generated ServiceType for protobuf service polarsignals.view.v1alpha1.ViewService
 */
export const ViewService = new ServiceType("polarsignals.view.v1alpha1.ViewService", [
    { name: "GetViews", options: { "google.api.http": { get: "/v1/{project_id}" } }, I: GetViewsRequest, O: GetViewsResponse },
    { name: "CreateView", options: { "google.api.http": { post: "/v1", body: "*" } }, I: CreateViewRequest, O: CreateViewResponse },
    { name: "GetView", options: { "google.api.http": { get: "/v1/{slug}" } }, I: GetViewRequest, O: GetViewResponse },
    { name: "UpdateView", options: { "google.api.http": { patch: "/v1/{slug}", body: "*" } }, I: UpdateViewRequest, O: UpdateViewResponse },
    { name: "DeleteView", options: { "google.api.http": { delete: "/v1/{slug}" } }, I: DeleteViewRequest, O: DeleteViewResponse }
]);
