// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "arrow/flight/protocol/Flight.proto" (package "arrow.flight.protocol", syntax proto3)
// tslint:disable
//
//
// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
// <p>
// http://www.apache.org/licenses/LICENSE-2.0
// <p>
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 *
 * The request that a client provides to a server on handshake.
 *
 * @generated from protobuf message arrow.flight.protocol.HandshakeRequest
 */
export interface HandshakeRequest {
    /**
     *
     * A defined protocol version
     *
     * @generated from protobuf field: uint64 protocol_version = 1;
     */
    protocolVersion: bigint;
    /**
     *
     * Arbitrary auth/handshake info.
     *
     * @generated from protobuf field: bytes payload = 2;
     */
    payload: Uint8Array;
}
/**
 * @generated from protobuf message arrow.flight.protocol.HandshakeResponse
 */
export interface HandshakeResponse {
    /**
     *
     * A defined protocol version
     *
     * @generated from protobuf field: uint64 protocol_version = 1;
     */
    protocolVersion: bigint;
    /**
     *
     * Arbitrary auth/handshake info.
     *
     * @generated from protobuf field: bytes payload = 2;
     */
    payload: Uint8Array;
}
/**
 *
 * A message for doing simple auth.
 *
 * @generated from protobuf message arrow.flight.protocol.BasicAuth
 */
export interface BasicAuth {
    /**
     * @generated from protobuf field: string username = 2;
     */
    username: string;
    /**
     * @generated from protobuf field: string password = 3;
     */
    password: string;
}
/**
 * @generated from protobuf message arrow.flight.protocol.Empty
 */
export interface Empty {
}
/**
 *
 * Describes an available action, including both the name used for execution
 * along with a short description of the purpose of the action.
 *
 * @generated from protobuf message arrow.flight.protocol.ActionType
 */
export interface ActionType {
    /**
     * @generated from protobuf field: string type = 1;
     */
    type: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
}
/**
 *
 * A service specific expression that can be used to return a limited set
 * of available Arrow Flight streams.
 *
 * @generated from protobuf message arrow.flight.protocol.Criteria
 */
export interface Criteria {
    /**
     * @generated from protobuf field: bytes expression = 1;
     */
    expression: Uint8Array;
}
/**
 *
 * An opaque action specific for the service.
 *
 * @generated from protobuf message arrow.flight.protocol.Action
 */
export interface Action {
    /**
     * @generated from protobuf field: string type = 1;
     */
    type: string;
    /**
     * @generated from protobuf field: bytes body = 2;
     */
    body: Uint8Array;
}
/**
 *
 * An opaque result returned after executing an action.
 *
 * @generated from protobuf message arrow.flight.protocol.Result
 */
export interface Result {
    /**
     * @generated from protobuf field: bytes body = 1;
     */
    body: Uint8Array;
}
/**
 *
 * Wrap the result of a getSchema call
 *
 * @generated from protobuf message arrow.flight.protocol.SchemaResult
 */
export interface SchemaResult {
    /**
     * The schema of the dataset in its IPC form:
     *   4 bytes - an optional IPC_CONTINUATION_TOKEN prefix
     *   4 bytes - the byte length of the payload
     *   a flatbuffer Message whose header is the Schema
     *
     * @generated from protobuf field: bytes schema = 1;
     */
    schema: Uint8Array;
}
/**
 *
 * The name or tag for a Flight. May be used as a way to retrieve or generate
 * a flight or be used to expose a set of previously defined flights.
 *
 * @generated from protobuf message arrow.flight.protocol.FlightDescriptor
 */
export interface FlightDescriptor {
    /**
     * @generated from protobuf field: arrow.flight.protocol.FlightDescriptor.DescriptorType type = 1;
     */
    type: FlightDescriptor_DescriptorType;
    /**
     *
     * Opaque value used to express a command. Should only be defined when
     * type = CMD.
     *
     * @generated from protobuf field: bytes cmd = 2;
     */
    cmd: Uint8Array;
    /**
     *
     * List of strings identifying a particular dataset. Should only be defined
     * when type = PATH.
     *
     * @generated from protobuf field: repeated string path = 3;
     */
    path: string[];
}
/**
 *
 * Describes what type of descriptor is defined.
 *
 * @generated from protobuf enum arrow.flight.protocol.FlightDescriptor.DescriptorType
 */
export enum FlightDescriptor_DescriptorType {
    /**
     * Protobuf pattern, not used.
     *
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     *
     * A named path that identifies a dataset. A path is composed of a string
     * or list of strings describing a particular dataset. This is conceptually
     *  similar to a path inside a filesystem.
     *
     * @generated from protobuf enum value: PATH = 1;
     */
    PATH = 1,
    /**
     *
     * An opaque command to generate a dataset.
     *
     * @generated from protobuf enum value: CMD = 2;
     */
    CMD = 2
}
/**
 *
 * The access coordinates for retrieval of a dataset. With a FlightInfo, a
 * consumer is able to determine how to retrieve a dataset.
 *
 * @generated from protobuf message arrow.flight.protocol.FlightInfo
 */
export interface FlightInfo {
    /**
     * The schema of the dataset in its IPC form:
     *   4 bytes - an optional IPC_CONTINUATION_TOKEN prefix
     *   4 bytes - the byte length of the payload
     *   a flatbuffer Message whose header is the Schema
     *
     * @generated from protobuf field: bytes schema = 1;
     */
    schema: Uint8Array;
    /**
     *
     * The descriptor associated with this info.
     *
     * @generated from protobuf field: arrow.flight.protocol.FlightDescriptor flight_descriptor = 2;
     */
    flightDescriptor?: FlightDescriptor;
    /**
     *
     * A list of endpoints associated with the flight. To consume the
     * whole flight, all endpoints (and hence all Tickets) must be
     * consumed. Endpoints can be consumed in any order.
     *
     * In other words, an application can use multiple endpoints to
     * represent partitioned data.
     *
     * If the returned data has an ordering, an application can use
     * "FlightInfo.ordered = true" or should return the all data in a
     * single endpoint. Otherwise, there is no ordering defined on
     * endpoints or the data within.
     *
     * A client can read ordered data by reading data from returned
     * endpoints, in order, from front to back.
     *
     * Note that a client may ignore "FlightInfo.ordered = true". If an
     * ordering is important for an application, an application must
     * choose one of them:
     *
     * * An application requires that all clients must read data in
     *   returned endpoints order.
     * * An application must return the all data in a single endpoint.
     *
     * @generated from protobuf field: repeated arrow.flight.protocol.FlightEndpoint endpoint = 3;
     */
    endpoint: FlightEndpoint[];
    /**
     * Set these to -1 if unknown.
     *
     * @generated from protobuf field: int64 total_records = 4;
     */
    totalRecords: bigint;
    /**
     * @generated from protobuf field: int64 total_bytes = 5;
     */
    totalBytes: bigint;
    /**
     *
     * FlightEndpoints are in the same order as the data.
     *
     * @generated from protobuf field: bool ordered = 6;
     */
    ordered: boolean;
    /**
     *
     * Application-defined metadata.
     *
     * There is no inherent or required relationship between this
     * and the app_metadata fields in the FlightEndpoints or resulting
     * FlightData messages. Since this metadata is application-defined,
     * a given application could define there to be a relationship,
     * but there is none required by the spec.
     *
     * @generated from protobuf field: bytes app_metadata = 7;
     */
    appMetadata: Uint8Array;
}
/**
 *
 * The information to process a long-running query.
 *
 * @generated from protobuf message arrow.flight.protocol.PollInfo
 */
export interface PollInfo {
    /**
     *
     * The currently available results.
     *
     * If "flight_descriptor" is not specified, the query is complete
     * and "info" specifies all results. Otherwise, "info" contains
     * partial query results.
     *
     * Note that each PollInfo response contains a complete
     * FlightInfo (not just the delta between the previous and current
     * FlightInfo).
     *
     * Subsequent PollInfo responses may only append new endpoints to
     * info.
     *
     * Clients can begin fetching results via DoGet(Ticket) with the
     * ticket in the info before the query is
     * completed. FlightInfo.ordered is also valid.
     *
     * @generated from protobuf field: arrow.flight.protocol.FlightInfo info = 1;
     */
    info?: FlightInfo;
    /**
     *
     * The descriptor the client should use on the next try.
     * If unset, the query is complete.
     *
     * @generated from protobuf field: arrow.flight.protocol.FlightDescriptor flight_descriptor = 2;
     */
    flightDescriptor?: FlightDescriptor;
    /**
     *
     * Query progress. If known, must be in [0.0, 1.0] but need not be
     * monotonic or nondecreasing. If unknown, do not set.
     *
     * @generated from protobuf field: optional double progress = 3;
     */
    progress?: number;
    /**
     *
     * Expiration time for this request. After this passes, the server
     * might not accept the retry descriptor anymore (and the query may
     * be cancelled). This may be updated on a call to PollFlightInfo.
     *
     * @generated from protobuf field: google.protobuf.Timestamp expiration_time = 4;
     */
    expirationTime?: Timestamp;
}
/**
 *
 * The request of the CancelFlightInfo action.
 *
 * The request should be stored in Action.body.
 *
 * @generated from protobuf message arrow.flight.protocol.CancelFlightInfoRequest
 */
export interface CancelFlightInfoRequest {
    /**
     * @generated from protobuf field: arrow.flight.protocol.FlightInfo info = 1;
     */
    info?: FlightInfo;
}
/**
 *
 * The result of the CancelFlightInfo action.
 *
 * The result should be stored in Result.body.
 *
 * @generated from protobuf message arrow.flight.protocol.CancelFlightInfoResult
 */
export interface CancelFlightInfoResult {
    /**
     * @generated from protobuf field: arrow.flight.protocol.CancelStatus status = 1;
     */
    status: CancelStatus;
}
/**
 *
 * An opaque identifier that the service can use to retrieve a particular
 * portion of a stream.
 *
 * Tickets are meant to be single use. It is an error/application-defined
 * behavior to reuse a ticket.
 *
 * @generated from protobuf message arrow.flight.protocol.Ticket
 */
export interface Ticket {
    /**
     * @generated from protobuf field: bytes ticket = 1;
     */
    ticket: Uint8Array;
}
/**
 *
 * A location where a Flight service will accept retrieval of a particular
 * stream given a ticket.
 *
 * @generated from protobuf message arrow.flight.protocol.Location
 */
export interface Location {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
}
/**
 *
 * A particular stream or split associated with a flight.
 *
 * @generated from protobuf message arrow.flight.protocol.FlightEndpoint
 */
export interface FlightEndpoint {
    /**
     *
     * Token used to retrieve this stream.
     *
     * @generated from protobuf field: arrow.flight.protocol.Ticket ticket = 1;
     */
    ticket?: Ticket;
    /**
     *
     * A list of URIs where this ticket can be redeemed via DoGet().
     *
     * If the list is empty, the expectation is that the ticket can only
     * be redeemed on the current service where the ticket was
     * generated.
     *
     * If the list is not empty, the expectation is that the ticket can be
     * redeemed at any of the locations, and that the data returned will be
     * equivalent. In this case, the ticket may only be redeemed at one of the
     * given locations, and not (necessarily) on the current service. If one
     * of the given locations is "arrow-flight-reuse-connection://?", the
     * client may redeem the ticket on the service where the ticket was
     * generated (i.e., the same as above), in addition to the other
     * locations. (This URI was chosen to maximize compatibility, as 'scheme:'
     * or 'scheme://' are not accepted by Java's java.net.URI.)
     *
     * In other words, an application can use multiple locations to
     * represent redundant and/or load balanced services.
     *
     * @generated from protobuf field: repeated arrow.flight.protocol.Location location = 2;
     */
    location: Location[];
    /**
     *
     * Expiration time of this stream. If present, clients may assume
     * they can retry DoGet requests. Otherwise, it is
     * application-defined whether DoGet requests may be retried.
     *
     * @generated from protobuf field: google.protobuf.Timestamp expiration_time = 3;
     */
    expirationTime?: Timestamp;
    /**
     *
     * Application-defined metadata.
     *
     * There is no inherent or required relationship between this
     * and the app_metadata fields in the FlightInfo or resulting
     * FlightData messages. Since this metadata is application-defined,
     * a given application could define there to be a relationship,
     * but there is none required by the spec.
     *
     * @generated from protobuf field: bytes app_metadata = 4;
     */
    appMetadata: Uint8Array;
}
/**
 *
 * The request of the RenewFlightEndpoint action.
 *
 * The request should be stored in Action.body.
 *
 * @generated from protobuf message arrow.flight.protocol.RenewFlightEndpointRequest
 */
export interface RenewFlightEndpointRequest {
    /**
     * @generated from protobuf field: arrow.flight.protocol.FlightEndpoint endpoint = 1;
     */
    endpoint?: FlightEndpoint;
}
/**
 *
 * A batch of Arrow data as part of a stream of batches.
 *
 * @generated from protobuf message arrow.flight.protocol.FlightData
 */
export interface FlightData {
    /**
     *
     * The descriptor of the data. This is only relevant when a client is
     * starting a new DoPut stream.
     *
     * @generated from protobuf field: arrow.flight.protocol.FlightDescriptor flight_descriptor = 1;
     */
    flightDescriptor?: FlightDescriptor;
    /**
     *
     * Header for message data as described in Message.fbs::Message.
     *
     * @generated from protobuf field: bytes data_header = 2;
     */
    dataHeader: Uint8Array;
    /**
     *
     * Application-defined metadata.
     *
     * @generated from protobuf field: bytes app_metadata = 3;
     */
    appMetadata: Uint8Array;
    /**
     *
     * The actual batch of Arrow data. Preferably handled with minimal-copies
     * coming last in the definition to help with sidecar patterns (it is
     * expected that some implementations will fetch this field off the wire
     * with specialized code to avoid extra memory copies).
     *
     * @generated from protobuf field: bytes data_body = 1000;
     */
    dataBody: Uint8Array;
}
/**
 * *
 * The response message associated with the submission of a DoPut.
 *
 * @generated from protobuf message arrow.flight.protocol.PutResult
 */
export interface PutResult {
    /**
     * @generated from protobuf field: bytes app_metadata = 1;
     */
    appMetadata: Uint8Array;
}
/**
 *
 * EXPERIMENTAL: Union of possible value types for a Session Option to be set to.
 *
 * By convention, an attempt to set a valueless SessionOptionValue should
 * attempt to unset or clear the named option value on the server.
 *
 * @generated from protobuf message arrow.flight.protocol.SessionOptionValue
 */
export interface SessionOptionValue {
    /**
     * @generated from protobuf oneof: option_value
     */
    optionValue: {
        oneofKind: "stringValue";
        /**
         * @generated from protobuf field: string string_value = 1;
         */
        stringValue: string;
    } | {
        oneofKind: "boolValue";
        /**
         * @generated from protobuf field: bool bool_value = 2;
         */
        boolValue: boolean;
    } | {
        oneofKind: "int64Value";
        /**
         * @generated from protobuf field: sfixed64 int64_value = 3;
         */
        int64Value: bigint;
    } | {
        oneofKind: "doubleValue";
        /**
         * @generated from protobuf field: double double_value = 4;
         */
        doubleValue: number;
    } | {
        oneofKind: "stringListValue";
        /**
         * @generated from protobuf field: arrow.flight.protocol.SessionOptionValue.StringListValue string_list_value = 5;
         */
        stringListValue: SessionOptionValue_StringListValue;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message arrow.flight.protocol.SessionOptionValue.StringListValue
 */
export interface SessionOptionValue_StringListValue {
    /**
     * @generated from protobuf field: repeated string values = 1;
     */
    values: string[];
}
/**
 *
 * EXPERIMENTAL: A request to set session options for an existing or new (implicit)
 * server session.
 *
 * Sessions are persisted and referenced via a transport-level state management, typically
 * RFC 6265 HTTP cookies when using an HTTP transport.  The suggested cookie name or state
 * context key is 'arrow_flight_session_id', although implementations may freely choose their
 * own name.
 *
 * Session creation (if one does not already exist) is implied by this RPC request, however
 * server implementations may choose to initiate a session that also contains client-provided
 * session options at any other time, e.g. on authentication, or when any other call is made
 * and the server wishes to use a session to persist any state (or lack thereof).
 *
 * @generated from protobuf message arrow.flight.protocol.SetSessionOptionsRequest
 */
export interface SetSessionOptionsRequest {
    /**
     * @generated from protobuf field: map<string, arrow.flight.protocol.SessionOptionValue> session_options = 1;
     */
    sessionOptions: {
        [key: string]: SessionOptionValue;
    };
}
/**
 *
 * EXPERIMENTAL: The results (individually) of setting a set of session options.
 *
 * Option names should only be present in the response if they were not successfully
 * set on the server; that is, a response without an Error for a name provided in the
 * SetSessionOptionsRequest implies that the named option value was set successfully.
 *
 * @generated from protobuf message arrow.flight.protocol.SetSessionOptionsResult
 */
export interface SetSessionOptionsResult {
    /**
     * @generated from protobuf field: map<string, arrow.flight.protocol.SetSessionOptionsResult.Error> errors = 1;
     */
    errors: {
        [key: string]: SetSessionOptionsResult_Error;
    };
}
/**
 * @generated from protobuf message arrow.flight.protocol.SetSessionOptionsResult.Error
 */
export interface SetSessionOptionsResult_Error {
    /**
     * @generated from protobuf field: arrow.flight.protocol.SetSessionOptionsResult.ErrorValue value = 1;
     */
    value: SetSessionOptionsResult_ErrorValue;
}
/**
 * @generated from protobuf enum arrow.flight.protocol.SetSessionOptionsResult.ErrorValue
 */
export enum SetSessionOptionsResult_ErrorValue {
    /**
     * Protobuf deserialization fallback value: The status is unknown or unrecognized.
     * Servers should avoid using this value. The request may be retried by the client.
     *
     * @generated from protobuf enum value: UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * The given session option name is invalid.
     *
     * @generated from protobuf enum value: INVALID_NAME = 1;
     */
    INVALID_NAME = 1,
    /**
     * The session option value or type is invalid.
     *
     * @generated from protobuf enum value: INVALID_VALUE = 2;
     */
    INVALID_VALUE = 2,
    /**
     * The session option cannot be set.
     *
     * @generated from protobuf enum value: ERROR = 3;
     */
    ERROR = 3
}
/**
 *
 * EXPERIMENTAL: A request to access the session options for the current server session.
 *
 * The existing session is referenced via a cookie header or similar (see
 * SetSessionOptionsRequest above); it is an error to make this request with a missing,
 * invalid, or expired session cookie header or other implementation-defined session
 * reference token.
 *
 * @generated from protobuf message arrow.flight.protocol.GetSessionOptionsRequest
 */
export interface GetSessionOptionsRequest {
}
/**
 *
 * EXPERIMENTAL: The result containing the current server session options.
 *
 * @generated from protobuf message arrow.flight.protocol.GetSessionOptionsResult
 */
export interface GetSessionOptionsResult {
    /**
     * @generated from protobuf field: map<string, arrow.flight.protocol.SessionOptionValue> session_options = 1;
     */
    sessionOptions: {
        [key: string]: SessionOptionValue;
    };
}
/**
 *
 * Request message for the "Close Session" action.
 *
 * The exiting session is referenced via a cookie header.
 *
 * @generated from protobuf message arrow.flight.protocol.CloseSessionRequest
 */
export interface CloseSessionRequest {
}
/**
 *
 * The result of closing a session.
 *
 * @generated from protobuf message arrow.flight.protocol.CloseSessionResult
 */
export interface CloseSessionResult {
    /**
     * @generated from protobuf field: arrow.flight.protocol.CloseSessionResult.Status status = 1;
     */
    status: CloseSessionResult_Status;
}
/**
 * @generated from protobuf enum arrow.flight.protocol.CloseSessionResult.Status
 */
export enum CloseSessionResult_Status {
    /**
     * Protobuf deserialization fallback value: The session close status is unknown or
     * not recognized. Servers should avoid using this value (send a NOT_FOUND error if
     * the requested session is not known or expired). Clients can retry the request.
     *
     * @generated from protobuf enum value: UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * The session close request is complete. Subsequent requests with
     * the same session produce a NOT_FOUND error.
     *
     * @generated from protobuf enum value: CLOSED = 1;
     */
    CLOSED = 1,
    /**
     * The session close request is in progress. The client may retry
     * the close request.
     *
     * @generated from protobuf enum value: CLOSING = 2;
     */
    CLOSING = 2,
    /**
     * The session is not closeable. The client should not retry the
     * close request.
     *
     * @generated from protobuf enum value: NOT_CLOSEABLE = 3;
     */
    NOT_CLOSEABLE = 3
}
/**
 *
 * The result of a cancel operation.
 *
 * This is used by CancelFlightInfoResult.status.
 *
 * @generated from protobuf enum arrow.flight.protocol.CancelStatus
 */
export enum CancelStatus {
    /**
     * The cancellation status is unknown. Servers should avoid using
     * this value (send a NOT_FOUND error if the requested query is
     * not known). Clients can retry the request.
     *
     * @generated from protobuf enum value: CANCEL_STATUS_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * The cancellation request is complete. Subsequent requests with
     * the same payload may return CANCELLED or a NOT_FOUND error.
     *
     * @generated from protobuf enum value: CANCEL_STATUS_CANCELLED = 1;
     */
    CANCELLED = 1,
    /**
     * The cancellation request is in progress. The client may retry
     * the cancellation request.
     *
     * @generated from protobuf enum value: CANCEL_STATUS_CANCELLING = 2;
     */
    CANCELLING = 2,
    /**
     * The query is not cancellable. The client should not retry the
     * cancellation request.
     *
     * @generated from protobuf enum value: CANCEL_STATUS_NOT_CANCELLABLE = 3;
     */
    NOT_CANCELLABLE = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class HandshakeRequest$Type extends MessageType<HandshakeRequest> {
    constructor() {
        super("arrow.flight.protocol.HandshakeRequest", [
            { no: 1, name: "protocol_version", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "payload", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<HandshakeRequest>): HandshakeRequest {
        const message = { protocolVersion: 0n, payload: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HandshakeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HandshakeRequest): HandshakeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 protocol_version */ 1:
                    message.protocolVersion = reader.uint64().toBigInt();
                    break;
                case /* bytes payload */ 2:
                    message.payload = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HandshakeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 protocol_version = 1; */
        if (message.protocolVersion !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.protocolVersion);
        /* bytes payload = 2; */
        if (message.payload.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.payload);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.HandshakeRequest
 */
export const HandshakeRequest = new HandshakeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HandshakeResponse$Type extends MessageType<HandshakeResponse> {
    constructor() {
        super("arrow.flight.protocol.HandshakeResponse", [
            { no: 1, name: "protocol_version", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "payload", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<HandshakeResponse>): HandshakeResponse {
        const message = { protocolVersion: 0n, payload: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HandshakeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HandshakeResponse): HandshakeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 protocol_version */ 1:
                    message.protocolVersion = reader.uint64().toBigInt();
                    break;
                case /* bytes payload */ 2:
                    message.payload = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HandshakeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 protocol_version = 1; */
        if (message.protocolVersion !== 0n)
            writer.tag(1, WireType.Varint).uint64(message.protocolVersion);
        /* bytes payload = 2; */
        if (message.payload.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.payload);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.HandshakeResponse
 */
export const HandshakeResponse = new HandshakeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BasicAuth$Type extends MessageType<BasicAuth> {
    constructor() {
        super("arrow.flight.protocol.BasicAuth", [
            { no: 2, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BasicAuth>): BasicAuth {
        const message = { username: "", password: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BasicAuth>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BasicAuth): BasicAuth {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string username */ 2:
                    message.username = reader.string();
                    break;
                case /* string password */ 3:
                    message.password = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BasicAuth, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string username = 2; */
        if (message.username !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.username);
        /* string password = 3; */
        if (message.password !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.password);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.BasicAuth
 */
export const BasicAuth = new BasicAuth$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Empty$Type extends MessageType<Empty> {
    constructor() {
        super("arrow.flight.protocol.Empty", []);
    }
    create(value?: PartialMessage<Empty>): Empty {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Empty>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Empty): Empty {
        return target ?? this.create();
    }
    internalBinaryWrite(message: Empty, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.Empty
 */
export const Empty = new Empty$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActionType$Type extends MessageType<ActionType> {
    constructor() {
        super("arrow.flight.protocol.ActionType", [
            { no: 1, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ActionType>): ActionType {
        const message = { type: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ActionType>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActionType): ActionType {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 1:
                    message.type = reader.string();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActionType, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 1; */
        if (message.type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.type);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.ActionType
 */
export const ActionType = new ActionType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Criteria$Type extends MessageType<Criteria> {
    constructor() {
        super("arrow.flight.protocol.Criteria", [
            { no: 1, name: "expression", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<Criteria>): Criteria {
        const message = { expression: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Criteria>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Criteria): Criteria {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes expression */ 1:
                    message.expression = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Criteria, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes expression = 1; */
        if (message.expression.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.expression);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.Criteria
 */
export const Criteria = new Criteria$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action$Type extends MessageType<Action> {
    constructor() {
        super("arrow.flight.protocol.Action", [
            { no: 1, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "body", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<Action>): Action {
        const message = { type: "", body: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Action>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Action): Action {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 1:
                    message.type = reader.string();
                    break;
                case /* bytes body */ 2:
                    message.body = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Action, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 1; */
        if (message.type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.type);
        /* bytes body = 2; */
        if (message.body.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.body);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.Action
 */
export const Action = new Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Result$Type extends MessageType<Result> {
    constructor() {
        super("arrow.flight.protocol.Result", [
            { no: 1, name: "body", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<Result>): Result {
        const message = { body: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Result): Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes body */ 1:
                    message.body = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes body = 1; */
        if (message.body.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.body);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.Result
 */
export const Result = new Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchemaResult$Type extends MessageType<SchemaResult> {
    constructor() {
        super("arrow.flight.protocol.SchemaResult", [
            { no: 1, name: "schema", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<SchemaResult>): SchemaResult {
        const message = { schema: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SchemaResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SchemaResult): SchemaResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes schema */ 1:
                    message.schema = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SchemaResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes schema = 1; */
        if (message.schema.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.schema);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.SchemaResult
 */
export const SchemaResult = new SchemaResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlightDescriptor$Type extends MessageType<FlightDescriptor> {
    constructor() {
        super("arrow.flight.protocol.FlightDescriptor", [
            { no: 1, name: "type", kind: "enum", T: () => ["arrow.flight.protocol.FlightDescriptor.DescriptorType", FlightDescriptor_DescriptorType] },
            { no: 2, name: "cmd", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "path", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FlightDescriptor>): FlightDescriptor {
        const message = { type: 0, cmd: new Uint8Array(0), path: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlightDescriptor>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlightDescriptor): FlightDescriptor {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* arrow.flight.protocol.FlightDescriptor.DescriptorType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* bytes cmd */ 2:
                    message.cmd = reader.bytes();
                    break;
                case /* repeated string path */ 3:
                    message.path.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlightDescriptor, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* arrow.flight.protocol.FlightDescriptor.DescriptorType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* bytes cmd = 2; */
        if (message.cmd.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.cmd);
        /* repeated string path = 3; */
        for (let i = 0; i < message.path.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.path[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.FlightDescriptor
 */
export const FlightDescriptor = new FlightDescriptor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlightInfo$Type extends MessageType<FlightInfo> {
    constructor() {
        super("arrow.flight.protocol.FlightInfo", [
            { no: 1, name: "schema", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "flight_descriptor", kind: "message", T: () => FlightDescriptor },
            { no: 3, name: "endpoint", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FlightEndpoint },
            { no: 4, name: "total_records", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "total_bytes", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 6, name: "ordered", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "app_metadata", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<FlightInfo>): FlightInfo {
        const message = { schema: new Uint8Array(0), endpoint: [], totalRecords: 0n, totalBytes: 0n, ordered: false, appMetadata: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlightInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlightInfo): FlightInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes schema */ 1:
                    message.schema = reader.bytes();
                    break;
                case /* arrow.flight.protocol.FlightDescriptor flight_descriptor */ 2:
                    message.flightDescriptor = FlightDescriptor.internalBinaryRead(reader, reader.uint32(), options, message.flightDescriptor);
                    break;
                case /* repeated arrow.flight.protocol.FlightEndpoint endpoint */ 3:
                    message.endpoint.push(FlightEndpoint.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 total_records */ 4:
                    message.totalRecords = reader.int64().toBigInt();
                    break;
                case /* int64 total_bytes */ 5:
                    message.totalBytes = reader.int64().toBigInt();
                    break;
                case /* bool ordered */ 6:
                    message.ordered = reader.bool();
                    break;
                case /* bytes app_metadata */ 7:
                    message.appMetadata = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlightInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes schema = 1; */
        if (message.schema.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.schema);
        /* arrow.flight.protocol.FlightDescriptor flight_descriptor = 2; */
        if (message.flightDescriptor)
            FlightDescriptor.internalBinaryWrite(message.flightDescriptor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated arrow.flight.protocol.FlightEndpoint endpoint = 3; */
        for (let i = 0; i < message.endpoint.length; i++)
            FlightEndpoint.internalBinaryWrite(message.endpoint[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 total_records = 4; */
        if (message.totalRecords !== 0n)
            writer.tag(4, WireType.Varint).int64(message.totalRecords);
        /* int64 total_bytes = 5; */
        if (message.totalBytes !== 0n)
            writer.tag(5, WireType.Varint).int64(message.totalBytes);
        /* bool ordered = 6; */
        if (message.ordered !== false)
            writer.tag(6, WireType.Varint).bool(message.ordered);
        /* bytes app_metadata = 7; */
        if (message.appMetadata.length)
            writer.tag(7, WireType.LengthDelimited).bytes(message.appMetadata);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.FlightInfo
 */
export const FlightInfo = new FlightInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PollInfo$Type extends MessageType<PollInfo> {
    constructor() {
        super("arrow.flight.protocol.PollInfo", [
            { no: 1, name: "info", kind: "message", T: () => FlightInfo },
            { no: 2, name: "flight_descriptor", kind: "message", T: () => FlightDescriptor },
            { no: 3, name: "progress", kind: "scalar", opt: true, T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "expiration_time", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<PollInfo>): PollInfo {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PollInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PollInfo): PollInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* arrow.flight.protocol.FlightInfo info */ 1:
                    message.info = FlightInfo.internalBinaryRead(reader, reader.uint32(), options, message.info);
                    break;
                case /* arrow.flight.protocol.FlightDescriptor flight_descriptor */ 2:
                    message.flightDescriptor = FlightDescriptor.internalBinaryRead(reader, reader.uint32(), options, message.flightDescriptor);
                    break;
                case /* optional double progress */ 3:
                    message.progress = reader.double();
                    break;
                case /* google.protobuf.Timestamp expiration_time */ 4:
                    message.expirationTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expirationTime);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PollInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* arrow.flight.protocol.FlightInfo info = 1; */
        if (message.info)
            FlightInfo.internalBinaryWrite(message.info, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* arrow.flight.protocol.FlightDescriptor flight_descriptor = 2; */
        if (message.flightDescriptor)
            FlightDescriptor.internalBinaryWrite(message.flightDescriptor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* optional double progress = 3; */
        if (message.progress !== undefined)
            writer.tag(3, WireType.Bit64).double(message.progress);
        /* google.protobuf.Timestamp expiration_time = 4; */
        if (message.expirationTime)
            Timestamp.internalBinaryWrite(message.expirationTime, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.PollInfo
 */
export const PollInfo = new PollInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelFlightInfoRequest$Type extends MessageType<CancelFlightInfoRequest> {
    constructor() {
        super("arrow.flight.protocol.CancelFlightInfoRequest", [
            { no: 1, name: "info", kind: "message", T: () => FlightInfo }
        ]);
    }
    create(value?: PartialMessage<CancelFlightInfoRequest>): CancelFlightInfoRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CancelFlightInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelFlightInfoRequest): CancelFlightInfoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* arrow.flight.protocol.FlightInfo info */ 1:
                    message.info = FlightInfo.internalBinaryRead(reader, reader.uint32(), options, message.info);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelFlightInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* arrow.flight.protocol.FlightInfo info = 1; */
        if (message.info)
            FlightInfo.internalBinaryWrite(message.info, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.CancelFlightInfoRequest
 */
export const CancelFlightInfoRequest = new CancelFlightInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelFlightInfoResult$Type extends MessageType<CancelFlightInfoResult> {
    constructor() {
        super("arrow.flight.protocol.CancelFlightInfoResult", [
            { no: 1, name: "status", kind: "enum", T: () => ["arrow.flight.protocol.CancelStatus", CancelStatus, "CANCEL_STATUS_"] }
        ]);
    }
    create(value?: PartialMessage<CancelFlightInfoResult>): CancelFlightInfoResult {
        const message = { status: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CancelFlightInfoResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelFlightInfoResult): CancelFlightInfoResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* arrow.flight.protocol.CancelStatus status */ 1:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelFlightInfoResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* arrow.flight.protocol.CancelStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.CancelFlightInfoResult
 */
export const CancelFlightInfoResult = new CancelFlightInfoResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Ticket$Type extends MessageType<Ticket> {
    constructor() {
        super("arrow.flight.protocol.Ticket", [
            { no: 1, name: "ticket", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<Ticket>): Ticket {
        const message = { ticket: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Ticket>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Ticket): Ticket {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes ticket */ 1:
                    message.ticket = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Ticket, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes ticket = 1; */
        if (message.ticket.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.ticket);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.Ticket
 */
export const Ticket = new Ticket$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Location$Type extends MessageType<Location> {
    constructor() {
        super("arrow.flight.protocol.Location", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Location>): Location {
        const message = { uri: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Location>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Location): Location {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Location, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.Location
 */
export const Location = new Location$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlightEndpoint$Type extends MessageType<FlightEndpoint> {
    constructor() {
        super("arrow.flight.protocol.FlightEndpoint", [
            { no: 1, name: "ticket", kind: "message", T: () => Ticket },
            { no: 2, name: "location", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Location },
            { no: 3, name: "expiration_time", kind: "message", T: () => Timestamp },
            { no: 4, name: "app_metadata", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<FlightEndpoint>): FlightEndpoint {
        const message = { location: [], appMetadata: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlightEndpoint>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlightEndpoint): FlightEndpoint {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* arrow.flight.protocol.Ticket ticket */ 1:
                    message.ticket = Ticket.internalBinaryRead(reader, reader.uint32(), options, message.ticket);
                    break;
                case /* repeated arrow.flight.protocol.Location location */ 2:
                    message.location.push(Location.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp expiration_time */ 3:
                    message.expirationTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expirationTime);
                    break;
                case /* bytes app_metadata */ 4:
                    message.appMetadata = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlightEndpoint, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* arrow.flight.protocol.Ticket ticket = 1; */
        if (message.ticket)
            Ticket.internalBinaryWrite(message.ticket, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated arrow.flight.protocol.Location location = 2; */
        for (let i = 0; i < message.location.length; i++)
            Location.internalBinaryWrite(message.location[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp expiration_time = 3; */
        if (message.expirationTime)
            Timestamp.internalBinaryWrite(message.expirationTime, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bytes app_metadata = 4; */
        if (message.appMetadata.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.appMetadata);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.FlightEndpoint
 */
export const FlightEndpoint = new FlightEndpoint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RenewFlightEndpointRequest$Type extends MessageType<RenewFlightEndpointRequest> {
    constructor() {
        super("arrow.flight.protocol.RenewFlightEndpointRequest", [
            { no: 1, name: "endpoint", kind: "message", T: () => FlightEndpoint }
        ]);
    }
    create(value?: PartialMessage<RenewFlightEndpointRequest>): RenewFlightEndpointRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RenewFlightEndpointRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RenewFlightEndpointRequest): RenewFlightEndpointRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* arrow.flight.protocol.FlightEndpoint endpoint */ 1:
                    message.endpoint = FlightEndpoint.internalBinaryRead(reader, reader.uint32(), options, message.endpoint);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RenewFlightEndpointRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* arrow.flight.protocol.FlightEndpoint endpoint = 1; */
        if (message.endpoint)
            FlightEndpoint.internalBinaryWrite(message.endpoint, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.RenewFlightEndpointRequest
 */
export const RenewFlightEndpointRequest = new RenewFlightEndpointRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlightData$Type extends MessageType<FlightData> {
    constructor() {
        super("arrow.flight.protocol.FlightData", [
            { no: 1, name: "flight_descriptor", kind: "message", T: () => FlightDescriptor },
            { no: 2, name: "data_header", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "app_metadata", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 1000, name: "data_body", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<FlightData>): FlightData {
        const message = { dataHeader: new Uint8Array(0), appMetadata: new Uint8Array(0), dataBody: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlightData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlightData): FlightData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* arrow.flight.protocol.FlightDescriptor flight_descriptor */ 1:
                    message.flightDescriptor = FlightDescriptor.internalBinaryRead(reader, reader.uint32(), options, message.flightDescriptor);
                    break;
                case /* bytes data_header */ 2:
                    message.dataHeader = reader.bytes();
                    break;
                case /* bytes app_metadata */ 3:
                    message.appMetadata = reader.bytes();
                    break;
                case /* bytes data_body */ 1000:
                    message.dataBody = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlightData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* arrow.flight.protocol.FlightDescriptor flight_descriptor = 1; */
        if (message.flightDescriptor)
            FlightDescriptor.internalBinaryWrite(message.flightDescriptor, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bytes data_header = 2; */
        if (message.dataHeader.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.dataHeader);
        /* bytes app_metadata = 3; */
        if (message.appMetadata.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.appMetadata);
        /* bytes data_body = 1000; */
        if (message.dataBody.length)
            writer.tag(1000, WireType.LengthDelimited).bytes(message.dataBody);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.FlightData
 */
export const FlightData = new FlightData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PutResult$Type extends MessageType<PutResult> {
    constructor() {
        super("arrow.flight.protocol.PutResult", [
            { no: 1, name: "app_metadata", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<PutResult>): PutResult {
        const message = { appMetadata: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PutResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PutResult): PutResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes app_metadata */ 1:
                    message.appMetadata = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PutResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes app_metadata = 1; */
        if (message.appMetadata.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.appMetadata);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.PutResult
 */
export const PutResult = new PutResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SessionOptionValue$Type extends MessageType<SessionOptionValue> {
    constructor() {
        super("arrow.flight.protocol.SessionOptionValue", [
            { no: 1, name: "string_value", kind: "scalar", oneof: "optionValue", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "bool_value", kind: "scalar", oneof: "optionValue", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "int64_value", kind: "scalar", oneof: "optionValue", T: 16 /*ScalarType.SFIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "double_value", kind: "scalar", oneof: "optionValue", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "string_list_value", kind: "message", oneof: "optionValue", T: () => SessionOptionValue_StringListValue }
        ]);
    }
    create(value?: PartialMessage<SessionOptionValue>): SessionOptionValue {
        const message = { optionValue: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SessionOptionValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SessionOptionValue): SessionOptionValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string string_value */ 1:
                    message.optionValue = {
                        oneofKind: "stringValue",
                        stringValue: reader.string()
                    };
                    break;
                case /* bool bool_value */ 2:
                    message.optionValue = {
                        oneofKind: "boolValue",
                        boolValue: reader.bool()
                    };
                    break;
                case /* sfixed64 int64_value */ 3:
                    message.optionValue = {
                        oneofKind: "int64Value",
                        int64Value: reader.sfixed64().toBigInt()
                    };
                    break;
                case /* double double_value */ 4:
                    message.optionValue = {
                        oneofKind: "doubleValue",
                        doubleValue: reader.double()
                    };
                    break;
                case /* arrow.flight.protocol.SessionOptionValue.StringListValue string_list_value */ 5:
                    message.optionValue = {
                        oneofKind: "stringListValue",
                        stringListValue: SessionOptionValue_StringListValue.internalBinaryRead(reader, reader.uint32(), options, (message.optionValue as any).stringListValue)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SessionOptionValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string string_value = 1; */
        if (message.optionValue.oneofKind === "stringValue")
            writer.tag(1, WireType.LengthDelimited).string(message.optionValue.stringValue);
        /* bool bool_value = 2; */
        if (message.optionValue.oneofKind === "boolValue")
            writer.tag(2, WireType.Varint).bool(message.optionValue.boolValue);
        /* sfixed64 int64_value = 3; */
        if (message.optionValue.oneofKind === "int64Value")
            writer.tag(3, WireType.Bit64).sfixed64(message.optionValue.int64Value);
        /* double double_value = 4; */
        if (message.optionValue.oneofKind === "doubleValue")
            writer.tag(4, WireType.Bit64).double(message.optionValue.doubleValue);
        /* arrow.flight.protocol.SessionOptionValue.StringListValue string_list_value = 5; */
        if (message.optionValue.oneofKind === "stringListValue")
            SessionOptionValue_StringListValue.internalBinaryWrite(message.optionValue.stringListValue, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.SessionOptionValue
 */
export const SessionOptionValue = new SessionOptionValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SessionOptionValue_StringListValue$Type extends MessageType<SessionOptionValue_StringListValue> {
    constructor() {
        super("arrow.flight.protocol.SessionOptionValue.StringListValue", [
            { no: 1, name: "values", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SessionOptionValue_StringListValue>): SessionOptionValue_StringListValue {
        const message = { values: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SessionOptionValue_StringListValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SessionOptionValue_StringListValue): SessionOptionValue_StringListValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string values */ 1:
                    message.values.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SessionOptionValue_StringListValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string values = 1; */
        for (let i = 0; i < message.values.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.values[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.SessionOptionValue.StringListValue
 */
export const SessionOptionValue_StringListValue = new SessionOptionValue_StringListValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetSessionOptionsRequest$Type extends MessageType<SetSessionOptionsRequest> {
    constructor() {
        super("arrow.flight.protocol.SetSessionOptionsRequest", [
            { no: 1, name: "session_options", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => SessionOptionValue } }
        ]);
    }
    create(value?: PartialMessage<SetSessionOptionsRequest>): SetSessionOptionsRequest {
        const message = { sessionOptions: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetSessionOptionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetSessionOptionsRequest): SetSessionOptionsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, arrow.flight.protocol.SessionOptionValue> session_options */ 1:
                    this.binaryReadMap1(message.sessionOptions, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: SetSessionOptionsRequest["sessionOptions"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SetSessionOptionsRequest["sessionOptions"] | undefined, val: SetSessionOptionsRequest["sessionOptions"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = SessionOptionValue.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field arrow.flight.protocol.SetSessionOptionsRequest.session_options");
            }
        }
        map[key ?? ""] = val ?? SessionOptionValue.create();
    }
    internalBinaryWrite(message: SetSessionOptionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, arrow.flight.protocol.SessionOptionValue> session_options = 1; */
        for (let k of Object.keys(message.sessionOptions)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            SessionOptionValue.internalBinaryWrite(message.sessionOptions[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.SetSessionOptionsRequest
 */
export const SetSessionOptionsRequest = new SetSessionOptionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetSessionOptionsResult$Type extends MessageType<SetSessionOptionsResult> {
    constructor() {
        super("arrow.flight.protocol.SetSessionOptionsResult", [
            { no: 1, name: "errors", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => SetSessionOptionsResult_Error } }
        ]);
    }
    create(value?: PartialMessage<SetSessionOptionsResult>): SetSessionOptionsResult {
        const message = { errors: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetSessionOptionsResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetSessionOptionsResult): SetSessionOptionsResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, arrow.flight.protocol.SetSessionOptionsResult.Error> errors */ 1:
                    this.binaryReadMap1(message.errors, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: SetSessionOptionsResult["errors"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SetSessionOptionsResult["errors"] | undefined, val: SetSessionOptionsResult["errors"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = SetSessionOptionsResult_Error.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field arrow.flight.protocol.SetSessionOptionsResult.errors");
            }
        }
        map[key ?? ""] = val ?? SetSessionOptionsResult_Error.create();
    }
    internalBinaryWrite(message: SetSessionOptionsResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, arrow.flight.protocol.SetSessionOptionsResult.Error> errors = 1; */
        for (let k of Object.keys(message.errors)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            SetSessionOptionsResult_Error.internalBinaryWrite(message.errors[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.SetSessionOptionsResult
 */
export const SetSessionOptionsResult = new SetSessionOptionsResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetSessionOptionsResult_Error$Type extends MessageType<SetSessionOptionsResult_Error> {
    constructor() {
        super("arrow.flight.protocol.SetSessionOptionsResult.Error", [
            { no: 1, name: "value", kind: "enum", T: () => ["arrow.flight.protocol.SetSessionOptionsResult.ErrorValue", SetSessionOptionsResult_ErrorValue] }
        ]);
    }
    create(value?: PartialMessage<SetSessionOptionsResult_Error>): SetSessionOptionsResult_Error {
        const message = { value: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetSessionOptionsResult_Error>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetSessionOptionsResult_Error): SetSessionOptionsResult_Error {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* arrow.flight.protocol.SetSessionOptionsResult.ErrorValue value */ 1:
                    message.value = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetSessionOptionsResult_Error, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* arrow.flight.protocol.SetSessionOptionsResult.ErrorValue value = 1; */
        if (message.value !== 0)
            writer.tag(1, WireType.Varint).int32(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.SetSessionOptionsResult.Error
 */
export const SetSessionOptionsResult_Error = new SetSessionOptionsResult_Error$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSessionOptionsRequest$Type extends MessageType<GetSessionOptionsRequest> {
    constructor() {
        super("arrow.flight.protocol.GetSessionOptionsRequest", []);
    }
    create(value?: PartialMessage<GetSessionOptionsRequest>): GetSessionOptionsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSessionOptionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSessionOptionsRequest): GetSessionOptionsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetSessionOptionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.GetSessionOptionsRequest
 */
export const GetSessionOptionsRequest = new GetSessionOptionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSessionOptionsResult$Type extends MessageType<GetSessionOptionsResult> {
    constructor() {
        super("arrow.flight.protocol.GetSessionOptionsResult", [
            { no: 1, name: "session_options", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => SessionOptionValue } }
        ]);
    }
    create(value?: PartialMessage<GetSessionOptionsResult>): GetSessionOptionsResult {
        const message = { sessionOptions: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSessionOptionsResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSessionOptionsResult): GetSessionOptionsResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, arrow.flight.protocol.SessionOptionValue> session_options */ 1:
                    this.binaryReadMap1(message.sessionOptions, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetSessionOptionsResult["sessionOptions"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetSessionOptionsResult["sessionOptions"] | undefined, val: GetSessionOptionsResult["sessionOptions"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = SessionOptionValue.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field arrow.flight.protocol.GetSessionOptionsResult.session_options");
            }
        }
        map[key ?? ""] = val ?? SessionOptionValue.create();
    }
    internalBinaryWrite(message: GetSessionOptionsResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, arrow.flight.protocol.SessionOptionValue> session_options = 1; */
        for (let k of Object.keys(message.sessionOptions)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            SessionOptionValue.internalBinaryWrite(message.sessionOptions[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.GetSessionOptionsResult
 */
export const GetSessionOptionsResult = new GetSessionOptionsResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CloseSessionRequest$Type extends MessageType<CloseSessionRequest> {
    constructor() {
        super("arrow.flight.protocol.CloseSessionRequest", []);
    }
    create(value?: PartialMessage<CloseSessionRequest>): CloseSessionRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CloseSessionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CloseSessionRequest): CloseSessionRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CloseSessionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.CloseSessionRequest
 */
export const CloseSessionRequest = new CloseSessionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CloseSessionResult$Type extends MessageType<CloseSessionResult> {
    constructor() {
        super("arrow.flight.protocol.CloseSessionResult", [
            { no: 1, name: "status", kind: "enum", T: () => ["arrow.flight.protocol.CloseSessionResult.Status", CloseSessionResult_Status] }
        ]);
    }
    create(value?: PartialMessage<CloseSessionResult>): CloseSessionResult {
        const message = { status: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CloseSessionResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CloseSessionResult): CloseSessionResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* arrow.flight.protocol.CloseSessionResult.Status status */ 1:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CloseSessionResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* arrow.flight.protocol.CloseSessionResult.Status status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message arrow.flight.protocol.CloseSessionResult
 */
export const CloseSessionResult = new CloseSessionResult$Type();
/**
 * @generated ServiceType for protobuf service arrow.flight.protocol.FlightService
 */
export const FlightService = new ServiceType("arrow.flight.protocol.FlightService", [
    { name: "Handshake", serverStreaming: true, clientStreaming: true, options: {}, I: HandshakeRequest, O: HandshakeResponse },
    { name: "ListFlights", serverStreaming: true, options: {}, I: Criteria, O: FlightInfo },
    { name: "GetFlightInfo", options: {}, I: FlightDescriptor, O: FlightInfo },
    { name: "PollFlightInfo", options: {}, I: FlightDescriptor, O: PollInfo },
    { name: "GetSchema", options: {}, I: FlightDescriptor, O: SchemaResult },
    { name: "DoGet", serverStreaming: true, options: {}, I: Ticket, O: FlightData },
    { name: "DoPut", serverStreaming: true, clientStreaming: true, options: {}, I: FlightData, O: PutResult },
    { name: "DoExchange", serverStreaming: true, clientStreaming: true, options: {}, I: FlightData, O: FlightData },
    { name: "DoAction", serverStreaming: true, options: {}, I: Action, O: Result },
    { name: "ListActions", serverStreaming: true, options: {}, I: Empty, O: ActionType }
]);
